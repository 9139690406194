import { CampaignChannels } from 'domains/campaigns/types';
import EmailPreviewContent from 'domains/content/components/EmailPreviewContent';
import { isTemplate } from 'domains/content/types';
import useSenderProfile from 'hooks/queries/useSenderProfile';
import useTemplate from 'hooks/queries/useTemplate';
import { CampaignStateTypes } from '../customCampaignReducer';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    contentPreviewId?: number;
    setContentPreviewId: (id: number) => void;
};

const CustomCampaignContentPreview = ({
    activeSendIndex,
    campaignState,
    contentPreviewId,
    setContentPreviewId,
}: Props) => {
    const { data: previewTemplate } = useTemplate({
        templateId: `${contentPreviewId}`,
        reactQueryOptions: {
            enabled: !!contentPreviewId,
        },
    });

    const currentSend = campaignState.sends[activeSendIndex];
    const isCurrentSendEmail = currentSend.channel === CampaignChannels.EMAIL;

    const { data: previewSenderProfile } = useSenderProfile(currentSend?.senderProfileId, {
        enabled: !!currentSend?.senderProfileId,
    });

    // TODO: build out preview for direct mail

    if (isCurrentSendEmail) {
        return (
            <EmailPreviewContent
                emailTemplateHTML={isTemplate(previewTemplate) && previewTemplate.html}
                fromAddress={previewSenderProfile?.from_address}
                isGridItem
                isOpen={!!contentPreviewId}
                preHeader={currentSend?.preHeader}
                subjectLine={currentSend?.subjectLine}
                onClose={() => setContentPreviewId(null)}
            />
        );
    }
};

export default CustomCampaignContentPreview;
