import { useHistory } from 'react-router';
import { SwapCalls } from '@mui/icons-material';
import { URLPaths } from 'models/enums';
import useCampaigns from 'hooks/queries/useCampaigns';
import useContacts from 'hooks/queries/useContacts';
import Button from 'domains/core/components/Button';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import PageLayout from 'domains/core/components/PageLayout';
import ContactsUploadDialog from 'domains/contacts/components/ContactsUploadDialog';
import FileUploadButton from 'domains/contacts/components/FileUploadButton';
import useContactsFileUploadProps from 'domains/contacts/hooks/useContactsFileUploadProps';
import CampaignsTable from './CampaignsTable';

export const CampaignsPage = () => {
    const history = useHistory();

    const {
        data: campaigns,
        isRefetching: isCampaignsRefetching,
        isSuccess: isCampaignsSuccess,
        refetch: refetchCampaigns,
    } = useCampaigns({
        refetchInterval: 30000,
    });
    const { data: contacts, isSuccess: isContactsSuccess } = useContacts({
        options: { limit: '1' },
    });

    const fileUploadProps = useContactsFileUploadProps();

    const isContactsEmpty = !contacts?.length;

    if (isContactsSuccess && isContactsEmpty) {
        return (
            <EmptyStateLayout
                caption="curations"
                header="create authentic connections"
                guidingText="Once your contacts are uploaded, build your first Curation to begin communicating with your contacts."
            >
                <ContactsUploadDialog {...fileUploadProps} />
                <FileUploadButton {...fileUploadProps} />
            </EmptyStateLayout>
        );
    }

    return (
        <PageLayout
            header="curations"
            headerAction={
                <Button
                    endIcon={<SwapCalls />}
                    onClick={() => history.push(URLPaths.CURATIONS_LANDING)}
                    variant="outlined"
                >
                    Create
                </Button>
            }
            headerIcon={<SwapCalls fontSize="inherit" />}
        >
            {isCampaignsSuccess && (
                <CampaignsTable campaigns={campaigns} isRefetching={isCampaignsRefetching} refetch={refetchCampaigns} />
            )}
        </PageLayout>
    );
};

export default CampaignsPage;
