import { useState } from 'react';
import FolderNamingDialog from 'domains/core/components/folders/FolderNamingDialog';
import { ContentType } from 'domains/content/types';
import useContentQueryManager from 'hooks/useContentQueryParams';
import useContentWithFolders from 'hooks/queries/useContentWithFolders';
import useCreateFolder from 'hooks/mutations/useCreateFolder';
import ContentPageLayout from '../ContentPageLayout';
import { contentTypeToLabel } from '../ContentPageLayout/ContentPageLayout';
import useGetCreateContentDropdownOptions from '../useGetCreateContentDropdownOptions';
import { isRootFolder } from '../ContentTable/utils';

const ContentPageWithFolders = () => {
    const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(false);

    const contentQueryManager = useContentQueryManager();
    const { changeViewParams, type, folderId } = contentQueryManager;
    const contentQuery = useContentWithFolders({ folderId, type });

    const { refetch: refetchContent } = contentQuery;

    const isUserOnRootLevel = isRootFolder(folderId);

    const createContentDropdownOptions = [
        ...useGetCreateContentDropdownOptions(),
        ...(isUserOnRootLevel
            ? [
                  {
                      label: 'folder',
                      onClick: () => setIsCreateFolderModalVisible(true),
                  },
              ]
            : []),
    ];

    const createFolder = (folderName: string) => {
        const body = {
            name: folderName,
        };
        saveFolder(body);
    };

    const onCreateFolderSuccess = (response: { id: number }) => {
        changeViewParams({ newFolderId: response.id });
        refetchContent();
        setIsCreateFolderModalVisible(false);
    };

    const { mutate: saveFolder, isLoading: isFolderSaving } = useCreateFolder({
        onSuccess: onCreateFolderSuccess,
    });

    const headerOtherDropdownProps = {
        label: contentTypeToLabel(type),
        options: [
            {
                label: contentTypeToLabel(ContentType.ALL),
                onClick: () => changeViewParams({ newType: ContentType.ALL }),
                selected: type === ContentType.ALL || !type,
            },
            {
                label: contentTypeToLabel(ContentType.FOLDER),
                onClick: () => changeViewParams({ newType: ContentType.FOLDER }),
                selected: type === ContentType.FOLDER,
            },
            {
                label: contentTypeToLabel(ContentType.EMAIL),
                onClick: () => changeViewParams({ newType: ContentType.EMAIL }),
                selected: type === ContentType.EMAIL,
            },
            {
                label: contentTypeToLabel(ContentType.PAGE),
                onClick: () => changeViewParams({ newType: ContentType.PAGE }),
                selected: type === ContentType.PAGE,
            },
        ],
    };

    return (
        <>
            <FolderNamingDialog
                data-testid="folder-name-input"
                disableActionButton={isFolderSaving}
                initialValue="Untitled Folder"
                isDialogVisible={isCreateFolderModalVisible}
                onClose={() => setIsCreateFolderModalVisible(false)}
                onSubmit={createFolder}
                title="create new folder"
            />
            <ContentPageLayout
                contentQuery={contentQuery}
                createContentDropdownOptions={createContentDropdownOptions}
                headerOtherDropdownProps={headerOtherDropdownProps}
                isNestedContent={!isUserOnRootLevel}
            />
        </>
    );
};

export default ContentPageWithFolders;
