import { useState } from 'react';
import { useHistory } from 'react-router';
import { SupervisedUserCircleOutlined } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MutationStatus, URLPaths } from 'models/enums';
import useMutationStatus from 'hooks/useMutationStatus';
import MutationKeys from 'hooks/mutations/keys';
import useContacts from 'hooks/queries/useContacts';
import useSegments from 'hooks/queries/useSegments';
import Button from 'domains/core/components/Button';
import DropdownButton from 'domains/core/components/DropdownButton';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import FolderNamingDialog from 'domains/core/components/folders/FolderNamingDialog';
import PageLayout from 'domains/core/components/PageLayout';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ContactsUploadDialog from 'domains/contacts/components/ContactsUploadDialog';
import FileUploadButton from 'domains/contacts/components/FileUploadButton';
import useContactsFileUploadProps from 'domains/contacts/hooks/useContactsFileUploadProps';
import SegmentsTable from './SegmentsTable';

const SegmentsPage = () => {
    const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(false);

    const history = useHistory();

    const { audienceFolders: hasAudienceFolders } = useFlags();

    const {
        data: contacts,
        error: contactsError,
        isError: isContactsError,
        isSuccess: isContactsSuccess,
    } = useContacts({ options: { limit: '1' } });
    const {
        data: segments,
        error: segmentsError,
        isError: isSegmentsError,
        isSuccess: isSegmentsSuccess,
        isRefetching: isSegmentsRefetching,
        refetch: refetchSegments,
    } = useSegments();

    const contactsFileUploadProps = useContactsFileUploadProps();

    const createAudienceStatus = useMutationStatus(MutationKeys.CREATE_AUDIENCE);
    const createSegmentStatus = useMutationStatus(MutationKeys.CREATE_SEGMENT);

    const isEmpty = isContactsSuccess && !contacts?.length;

    if (isEmpty) {
        return (
            <EmptyStateLayout
                caption="audience"
                header="build your audience"
                guidingText="Once your contacts are uploaded, start segmenting your contacts into audiences to reach the most relevant recipients"
            >
                <ContactsUploadDialog {...contactsFileUploadProps} />
                <FileUploadButton {...contactsFileUploadProps} />
            </EmptyStateLayout>
        );
    }

    const getHeaderAction = () => {
        const icon = <SupervisedUserCircleOutlined />;
        const handleCreateAudienceClick = () => history.push(URLPaths.SEGMENT_CREATE);

        if (hasAudienceFolders) {
            return (
                <DropdownButton
                    ariaLabel="choose what to create"
                    icon={icon}
                    label="Create"
                    options={[
                        { label: 'Audience', onClick: handleCreateAudienceClick },
                        { label: 'Folder', onClick: () => setIsCreateFolderModalVisible(true) },
                    ]}
                    size="medium"
                    variant="outlined"
                />
            );
        }

        return (
            <Button endIcon={icon} onClick={handleCreateAudienceClick} variant="outlined">
                Create
            </Button>
        );
    };
    const headerAction = getHeaderAction();

    return (
        <PageLayout
            header="audiences"
            headerIcon={<SupervisedUserCircleOutlined fontSize="inherit" />}
            headerAction={headerAction}
        >
            <FolderNamingDialog
                disableActionButton
                initialValue="Untitled Folder"
                isDialogVisible={isCreateFolderModalVisible}
                onClose={() => setIsCreateFolderModalVisible(false)}
                onSubmit={() => null}
                title="create new folder"
            />
            {(createAudienceStatus === MutationStatus.SUCCESS || createSegmentStatus === MutationStatus.SUCCESS) && (
                <SuccessSnackbar successMessage="Audience saved successfully." />
            )}
            {isContactsError && <ErrorSnackbar errorMessage={contactsError?.message} />}
            {isSegmentsError && <ErrorSnackbar errorMessage={segmentsError?.message} />}
            {isSegmentsSuccess && (
                <SegmentsTable isRefetching={isSegmentsRefetching} refetch={refetchSegments} segments={segments} />
            )}
        </PageLayout>
    );
};

export default SegmentsPage;
