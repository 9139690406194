import { SendRecurrenceFrequency, SplitActions } from 'domains/campaigns/types';
import { SegmentFilterCategorySpecificField, SegmentFilterState } from 'domains/segments/types';

export enum actionTypes {
    SET_NAME = 'SET_NAME',
    SELECT_AUDIENCE = 'SELECT_AUDIENCE',
    SELECT_CONTENT = 'SELECT_CONTENT',
    SET_CONTENT = 'SET_CONTENT',
    SET_SENDER_PROFILE_ID = 'SET_SENDER_PROFILE_ID',
    SET_SUBJECT_LINE = 'SET_SUBJECT_LINE',
    SET_PRE_HEADER = 'SET_PRE_HEADER',
    SET_SCHEDULED_TIMESTAMP = 'SET_SCHEDULED_TIMESTAMP',
    SET_HAS_RECOMMENDED_TIME = 'SET_HAS_RECOMMENDED_TIME',
    SET_RECURRENCE_FREQUENCY = 'SET_RECURRENCE_FREQUENCY',
    SET_SCHEDULED_END_TIMESTAMP = 'SET_SCHEDULED_END_TIMESTAMP',
    SET_PARENT_AUDIENCE_DELAY_INTERVAL = 'SET_PARENT_AUDIENCE_DELAY_INTERVAL',
    ADD_CONDITIONAL_SPLIT_SENDS = 'ADD_CONDITIONAL_SPLIT_SENDS',
    SET_SEGMENT = 'SET_SEGMENT',
    SET_SPLIT_ACTION = 'SET_SPLIT_ACTION',
    ADD_SEND = 'ADD_SEND',
    REMOVE_SEND = 'REMOVE_SEND',
    RESET_CONDITIONAL_SPLIT_SEND = 'RESET_CONDITIONAL_SPLIT_SEND',
    RESET_CONTENT = 'RESET_CONTENT',
}

// Actions
export const setName = (name: string) => ({ type: actionTypes.SET_NAME, name });

export const selectAudience = (selectedAudienceId: number | null) => ({
    type: actionTypes.SELECT_AUDIENCE,
    selectedAudienceId,
});

// TODO: Rename to setTemplate to differentiate from
// ?: Consider renaming SET_CONTENT to SET_DIRECT_MAIL (and associated type definition) to further differentiate since 'content' is bit too generic
export const selectContent = (templateId: number | null, sendIndex: number) => ({
    type: actionTypes.SELECT_CONTENT,
    parameters: { templateId, sendIndex },
});

export const setContent = (
    { contentId, contentName }: { contentId: number | null; contentName: string },
    sendIndex: number
) => ({
    type: actionTypes.SET_CONTENT,
    parameters: { contentId, contentName, sendIndex },
});

export const setSenderProfileId = (senderProfileId: number | null, sendIndex: number) => ({
    type: actionTypes.SET_SENDER_PROFILE_ID,
    parameters: { sendIndex, senderProfileId },
});

export const setSubjectLine = (subjectLine: string, sendIndex: number) => ({
    type: actionTypes.SET_SUBJECT_LINE,
    parameters: { sendIndex, subjectLine },
});

export const setPreHeader = (preHeader: string, sendIndex: number) => ({
    type: actionTypes.SET_PRE_HEADER,
    parameters: { preHeader, sendIndex },
});

export const setScheduledTimestamp = (scheduledTimestamp: string, sendIndex: number) => ({
    type: actionTypes.SET_SCHEDULED_TIMESTAMP,
    parameters: { scheduledTimestamp, sendIndex },
});

export const setHasRecommendedTime = (hasRecommendedTime: boolean) => ({
    type: actionTypes.SET_HAS_RECOMMENDED_TIME,
    parameters: { hasRecommendedTime },
});

export const setRecurrenceFrequency = (recurrenceFrequency: SendRecurrenceFrequency) => ({
    type: actionTypes.SET_RECURRENCE_FREQUENCY,
    parameters: { recurrenceFrequency },
});

export const setScheduledEndTimestamp = (scheduledEndTimestamp: string) => ({
    type: actionTypes.SET_SCHEDULED_END_TIMESTAMP,
    parameters: { scheduledEndTimestamp },
});

export const setParentAudienceDelayInterval = (parentAudienceDelayInterval: string, sendIndex: number) => ({
    type: actionTypes.SET_PARENT_AUDIENCE_DELAY_INTERVAL,
    parameters: { parentAudienceDelayInterval, sendIndex },
});

export const addConditionalSplitSends = (sendIndex: number) => ({
    type: actionTypes.ADD_CONDITIONAL_SPLIT_SENDS,
    parameters: { sendIndex },
});

export const setSegment = (segment: SegmentFilterState<SegmentFilterCategorySpecificField>, sendIndex: number) => ({
    type: actionTypes.SET_SEGMENT,
    parameters: { segment, sendIndex },
});

export const setSplitAction = (sendIndex: number, splitAction: SplitActions) => ({
    type: actionTypes.SET_SPLIT_ACTION,
    parameters: { sendIndex, splitAction },
});

export const addSend = (parentIndex: number) => ({
    type: actionTypes.ADD_SEND,
    parameters: { parentIndex },
});

export const removeSend = () => ({ type: actionTypes.REMOVE_SEND });

export const resetConditionalSplitSend = (sendIndex: number) => ({
    type: actionTypes.RESET_CONDITIONAL_SPLIT_SEND,
    parameters: { sendIndex },
});

export const resetContent = (sendIndex: number) => ({
    type: actionTypes.RESET_CONTENT,
    parameters: { sendIndex },
});
