import { ArrowForward } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import articleImage from 'assets/images/svg/homepage/article-image.svg';
import logoImage from 'assets/images/svg/cured-innovaccer.svg';
import theme from 'theme';

const callToActionLink =
    'https://www.businesswire.com/news/home/20241112913730/en/Cured-by-Innovaccer-Launches-AI-Capabilities-to-Create-Intelligent-Personalized-Healthcare-Experiences';

const containerStyles = {
    backgroundColor: theme.palette.other.water,
    height: '100%',
    paddingX: 8,
    position: 'relative',
};

const buttonStyles = {
    alignSelf: 'flex-start',
    border: '1px solid transparent',
    margin: '20px 0 0 -20px',
    '&:hover': {
        borderColor: theme.palette.text.secondary,
    },
};

const imageWrapperStyles = {
    height: '100%',
};

const logoStyles = {
    alignItems: 'end',
    paddingBottom: 4,
};

const Article = () => (
    <Stack sx={containerStyles}>
        <Stack>
            <Typography variant="caption" color="action.active" paddingTop={7}>
                healthcare experiences ai
            </Typography>
            <Typography variant="h3" paddingTop={1} color="primary.main">
                increase patient engagement, operational efficiency, and revenue growth
            </Typography>
            <Button
                size="large"
                sx={buttonStyles}
                endIcon={<ArrowForward fontSize="inherit" />}
                onClick={() => window.open(callToActionLink, '_blank')}
            >
                Learn More
            </Button>
        </Stack>
        <Stack sx={imageWrapperStyles} flexDirection="row">
            <img width="100%" src={articleImage} alt="" />
        </Stack>
        <Stack sx={logoStyles}>
            <img height="48px" src={logoImage} alt="cured by innovaccer logo" />
        </Stack>
    </Stack>
);

export default Article;
