import { useState, ChangeEvent } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import theme from 'theme';
import { getTimezone } from 'utils';
import { ScheduleFields } from 'domains/campaigns/types';
import {
    getSendDatetimeError,
    getEndDateTimeError,
    maxDate,
    maxTime,
    minDate,
    minTime,
    getIsRecurrenceEnabled,
    getIsRecurrenceContinuous,
} from 'domains/campaigns/utils';
import DatePicker from 'domains/core/components/DatePicker';
import TimePicker from 'domains/core/components/TimePicker';
import RecommendedTimeSwitch from './RecommendedTimeSwitch';
import { getRecommendedTime } from './utils/getRecommendedTime';
import RecurrenceDropdown from './RecurrenceDropdown';

const getLabelPrefix = (isRecurrenceContinuous: boolean): string => (isRecurrenceContinuous ? 'Start' : 'Send');
const getDateLabel = (isRecurrenceContinuous: boolean): string => `${getLabelPrefix(isRecurrenceContinuous)} Date`;
const getTimeLabel = (isRecurrenceContinuous: boolean): string =>
    `${getLabelPrefix(isRecurrenceContinuous)} Time (${getTimezone()})`;

export type Props = {
    scheduleFields: ScheduleFields;
    allowRecurrence?: boolean;
    editSchedule: (payload: Partial<ScheduleFields>) => void;
    setDatetimeError: (isError: boolean) => void;
};

const CurationScheduleForm = ({ scheduleFields, allowRecurrence, editSchedule, setDatetimeError }: Props) => {
    const { scheduledTimestamp, isRecommendedTimeEnabled, recurrenceFrequency, scheduledEndTimestamp } = scheduleFields;
    const sendDatetimeLuxon = scheduledTimestamp ? DateTime.fromISO(scheduledTimestamp) : DateTime.now();
    const endDatetimeLuxon = scheduledEndTimestamp ? DateTime.fromISO(scheduledEndTimestamp) : null;

    const [recommendedTimeSwitchTouched, setRecommendedTimeSwitchTouched] = useState(false);

    const [sendDate, setSendDate] = useState<DateTime>(scheduledTimestamp ? sendDatetimeLuxon : null);
    const [sendTime, setSendTime] = useState<DateTime>(scheduledTimestamp ? sendDatetimeLuxon : null);
    const [hasEndDate, setHasEndDate] = useState(scheduledEndTimestamp ? true : false);
    const [endDate, setEndDate] = useState<DateTime>(endDatetimeLuxon);

    const isRecurrenceContinuous = getIsRecurrenceContinuous(recurrenceFrequency);
    const isRecurrenceEnabled = getIsRecurrenceEnabled(recurrenceFrequency);

    const handleRecommendedTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            const newDatetime = getRecommendedTime(sendDate, recurrenceFrequency);
            setSendTime(newDatetime);
            editSchedule({
                ...scheduleFields,
                scheduledTimestamp: newDatetime.toISO(),
                isRecommendedTimeEnabled: true,
            });
        } else {
            editSchedule({
                ...scheduleFields,
                isRecommendedTimeEnabled: false,
            });
        }
        setRecommendedTimeSwitchTouched(true);
    };

    const onChangeSendDatetime = (datetime: DateTime, isDate: boolean, setState: (datetime: DateTime) => void) => {
        setState(datetime);

        if (datetime && datetime.isValid) {
            const newDate = sendDatetimeLuxon.set({
                year: datetime.year,
                month: datetime.month,
                day: datetime.day,
                hour: sendDatetimeLuxon.hour,
                minute: sendDatetimeLuxon.minute,
            });
            const newTime = sendDatetimeLuxon.set({
                year: sendDatetimeLuxon.year,
                month: sendDatetimeLuxon.month,
                day: sendDatetimeLuxon.day,
                hour: datetime.hour,
                minute: datetime.minute,
            });
            const newDatetime = isDate ? newDate : newTime;
            const shouldUpdateRecommendedTime = isRecommendedTimeEnabled && isDate;
            const recommendedTime = getRecommendedTime(newDatetime, recurrenceFrequency);

            editSchedule({
                ...scheduleFields,
                scheduledTimestamp: shouldUpdateRecommendedTime ? recommendedTime.toISO() : newDatetime.toISO(),
                // If the time was changed, uncheck the recommendedTime
                isRecommendedTimeEnabled: !isDate ? false : scheduleFields.isRecommendedTimeEnabled,
            });

            if (!Boolean(getSendDatetimeError(newDatetime, isDate))) {
                setDatetimeError(false);

                setSendDate(newDatetime);
                setSendTime(shouldUpdateRecommendedTime ? recommendedTime : newDatetime);
            } else {
                setDatetimeError(true);
            }
            if (!isRecurrenceEnabled) {
                setEndDate(newDatetime);
            }
        } else {
            setDatetimeError(true);
        }
        setRecommendedTimeSwitchTouched(true);
    };

    const onChangeHasEndDate = (event: ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === 'true') {
            setHasEndDate(true);
            setEndDate(sendDate);
        } else {
            editSchedule({
                ...scheduleFields,
                scheduledEndTimestamp: '',
            });
            setHasEndDate(false);
            setEndDate(null);
        }
        setRecommendedTimeSwitchTouched(true);
    };

    const onChangeEndDatetime = (datetime: DateTime) => {
        setEndDate(datetime);

        if (datetime?.isValid) {
            const newDatetime = datetime.set({ year: datetime.year, month: datetime.month, day: datetime.day });
            editSchedule({
                ...scheduleFields,
                scheduledEndTimestamp: newDatetime.toISO(),
            });
            setEndDate(newDatetime);
        }

        setRecommendedTimeSwitchTouched(true);
    };

    const sendDateErrorMessage = sendDate && getSendDatetimeError(sendDate, true);
    const sendTimeErrorMessage = sendTime && getSendDatetimeError(sendTime, false);
    const endDateErrorMessage = getEndDateTimeError(endDate, sendDatetimeLuxon);
    const hasEndDateError = hasEndDate && endDate;

    const shouldShowReccurenceDropdown = allowRecurrence && sendDate;
    const shouldShowEndDateTimePicker = allowRecurrence && sendDate && isRecurrenceEnabled;
    const shouldShowEndDateErrorMessage = hasEndDateError && Boolean(endDateErrorMessage);

    return (
        <Stack rowGap={4}>
            <DatePicker
                data-testid="date-picker"
                label={getDateLabel(isRecurrenceContinuous)}
                value={sendDate}
                minDate={minDate()}
                maxDate={maxDate()}
                onChange={(datetime) => onChangeSendDatetime(datetime, true, setSendDate)}
                slotProps={{
                    textField: {
                        error: !!sendDateErrorMessage,
                        helperText: sendDateErrorMessage,
                        required: true,
                        sx: { width: '100%' },
                    },
                    popper: {
                        style: { paddingRight: theme.spacing(3.25) },
                    },
                }}
            />
            <TimePicker
                label={getTimeLabel(isRecurrenceContinuous)}
                value={sendTime}
                minTime={minTime(scheduledTimestamp)}
                maxTime={maxTime(scheduledTimestamp)}
                onChange={(datetime) => onChangeSendDatetime(datetime, false, setSendTime)}
                slotProps={{
                    textField: {
                        error: !!sendTimeErrorMessage,
                        helperText: sendTimeErrorMessage,
                        required: true,
                        sx: { width: '100%' },
                    },
                }}
                sx={{ pb: 2 }}
            />
            <Box my={-1} pl={1}>
                <RecommendedTimeSwitch
                    isChecked={isRecommendedTimeEnabled}
                    isDisabled={!!sendDateErrorMessage}
                    isTouched={recommendedTimeSwitchTouched}
                    recurrenceFrequency={recurrenceFrequency}
                    sendDate={sendDate}
                    onChange={handleRecommendedTimeChange}
                />
            </Box>
            {shouldShowReccurenceDropdown && (
                <RecurrenceDropdown
                    scheduleFields={scheduleFields}
                    sendDate={sendDate}
                    editSchedule={editSchedule}
                    setEndDate={setEndDate}
                    setHasEndDate={setHasEndDate}
                    setRecommendedTimeSwitchTouched={setRecommendedTimeSwitchTouched}
                    setSendTime={setSendTime}
                />
            )}
            {shouldShowEndDateTimePicker && (
                <>
                    <FormControl>
                        <Stack direction="row" columnGap={2} alignItems="center" my={-2}>
                            <FormLabel>
                                <Typography variant="body2">End Date/Time</Typography>
                            </FormLabel>
                            <RadioGroup
                                value={hasEndDate}
                                onChange={onChangeHasEndDate}
                                defaultValue={false}
                                name="end-date-group"
                                row
                            >
                                <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" color="secondary" />}
                                    label={<Typography variant="body2">Never</Typography>}
                                />
                                <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" color="secondary" />}
                                    label={<Typography variant="body2">Ends On</Typography>}
                                    data-testid="recurrence-end-date-label"
                                />
                            </RadioGroup>
                        </Stack>
                    </FormControl>
                    {hasEndDate && (
                        <DateTimePicker
                            data-testid="send-date-picker"
                            label={
                                <>
                                    End Date/Time ({getTimezone()})
                                    <Tooltip title="This is the last day that any sends will go out.">
                                        <InfoOutlined style={{ fontSize: '14px', margin: '0 0 -2px 6px' }} />
                                    </Tooltip>
                                </>
                            }
                            minDateTime={minDate()}
                            slotProps={{
                                textField: {
                                    error: shouldShowEndDateErrorMessage,
                                    helperText: hasEndDateError && endDateErrorMessage,
                                    id: 'end-datetime-picker',
                                },
                                popper: {
                                    placement: 'bottom-end',
                                },
                            }}
                            value={endDate}
                            onChange={(datetime) => onChangeEndDatetime(datetime)}
                        />
                    )}
                </>
            )}
        </Stack>
    );
};

export default CurationScheduleForm;
