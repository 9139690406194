import { useState, useEffect } from 'react';
import {
    CreateLandingPageInput,
    CreateTemplateInput,
    UpdateLandingPageInput,
    UpdateTemplateInput,
} from 'domains/content/types';

export type Params = {
    beeJson: string;
    id: string;
    isNameTooShort: boolean;
    isSaveClicked: boolean;
    isTemplate: boolean;
    isTemplateLayout: boolean;
    isUpdate: boolean;
    name: string;
    onSave: (input: CreateLandingPageInput | CreateTemplateInput) => void;
    onUpdate: (input: UpdateLandingPageInput | UpdateTemplateInput) => void;
    parentFolderId?: number;
};

/**
 * We can not call the mutations from Bee's callbacks directly because when the callbacks
 * are invoked the state like templateName is set to the initial value when the Bee plugin
 * was first rendered (possibly a bug with our code or an issue with the Bee Plugin).
 *
 * Autosave updates the beeJson which will trigger a save/update at the interval from the config if there are unsaved changes.
 * Saving is disabled for layouts.
 * Changing the template name will immediately trigger a save/update.
 * Manually clicking the save button will trigger a save/update.
 */
const useBeeAutoSave = ({
    beeJson,
    id,
    isNameTooShort,
    isSaveClicked,
    isTemplate,
    isTemplateLayout,
    isUpdate,
    name,
    onSave,
    onUpdate,
    parentFolderId,
}: Params) => {
    // We can update a template multiple times, but only want to create a template once.
    const [isCreated, setIsCreated] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState([]);

    const trackChange = (change: object) => setUnsavedChanges([...unsavedChanges, change]);

    useEffect(() => {
        const isNameValid = name && !isNameTooShort;
        const isBeeJsonChangesValid = !!beeJson && !!unsavedChanges.length;

        if (isTemplateLayout) {
            return;
        }
        if (!isNameValid) {
            return;
        }
        if (!isSaveClicked && !isBeeJsonChangesValid) {
            return;
        }

        const mutationInput = {
            beeJson,
            ...(isTemplate ? { templateName: name } : { name }),
            ...(parentFolderId ? { parentFolderId } : {}),
        };

        if (isUpdate) {
            onUpdate({ id, ...mutationInput });
        } else if (!isCreated) {
            onSave(mutationInput);
            setIsCreated(true);
        }

        setUnsavedChanges([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beeJson, isSaveClicked, isTemplateLayout, name]);

    return { trackChange, isDirty: Boolean(unsavedChanges.length) };
};

export default useBeeAutoSave;
