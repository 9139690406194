import { NavigateNext } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import { Content } from 'domains/content/types';
import theme from 'theme';
import ContentRow from '../ContentRow';

const border = `1px solid ${theme.palette.primary.main}`;

const breadcrumbWrapperStyles = {
    padding: '0.5rem 1.5rem',
    borderTop: `1px solid ${theme.palette.primary.main}`,
};

const activeBreadcrumbStyles = {
    color: theme.palette.action.active,
    padding: 1,
};

const contentWrapperStyles = {
    borderTop: border,
    padding: '1rem 1rem 0',
};

type Props = {
    data: Content[];
    previewedLocation: Content;
    resetPreview: () => void;
};

const PreviewContent = ({ data, previewedLocation, resetPreview }: Props) => {
    const renderContent = () => {
        if (!data?.length) {
            return (
                <Typography align="center" sx={{ padding: '1rem' }} variant="body2">
                    No content to preview.
                </Typography>
            );
        }

        return data?.map((content) => <ContentRow key={content.id} content={content} isPreview />);
    };

    return (
        <>
            <Stack direction="row" sx={breadcrumbWrapperStyles}>
                <Button
                    endIcon={<NavigateNext />}
                    onClick={resetPreview}
                    size="small"
                    sx={{ padding: 1 }}
                    variant="text"
                >
                    All Content
                </Button>
                <Button disabled size="small" sx={activeBreadcrumbStyles} variant="text">
                    {previewedLocation.name}
                </Button>
            </Stack>
            <Stack sx={contentWrapperStyles}>{renderContent()}</Stack>
        </>
    );
};

export default PreviewContent;
