import { CreateNewFolderOutlined, DriveFileRenameOutline } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import { useEffect, useState } from 'react';
import TextField from 'domains/core/components/TextField';

export type Props = {
    disableActionButton?: boolean;
    initialValue: string;
    isDialogVisible: boolean;
    onClose: () => void;
    onSubmit: (value: string, folderId?: number) => void;
    submitButtonText?: string;
    title: string;
};

const FolderNamingDialog = ({
    disableActionButton,
    initialValue,
    isDialogVisible,
    onClose,
    onSubmit,
    submitButtonText,
    title,
}: Props) => {
    const [error, setError] = useState(false);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const isValidFolderName = (inputValue: String) => inputValue.trim().length >= 3;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setValue(inputValue);

        if (!isValidFolderName(inputValue)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const renderIcon = () => {
        const Icon = title === 'Rename' ? DriveFileRenameOutline : CreateNewFolderOutlined;
        return <Icon sx={{ paddingTop: '2px' }} />;
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={isDialogVisible}
            onClose={onClose}
            TransitionProps={{
                onExited: () => {
                    setValue(initialValue);
                    setError(false);
                },
            }}
        >
            <DialogTitle>
                <Stack alignItems="center" direction="row" spacing={1}>
                    {renderIcon()}
                    <Typography variant="h6">{title}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ padding: '16px 32px' }}>
                <TextField
                    data-testid="folder-name-input"
                    margin="dense"
                    error={error}
                    value={value}
                    helperText={error ? 'Folder name must have at least 3 characters' : ''}
                    label="Folder Name"
                    onChange={handleChange}
                    inputProps={{ maxLength: 255 }}
                    autoFocus
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    data-testid="folder-name-input-submit"
                    disabled={error || disableActionButton}
                    variant="contained"
                    onClick={() => onSubmit(value.trim())}
                >
                    {submitButtonText ?? 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default FolderNamingDialog;
