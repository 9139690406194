import { CallSplit, DoNotDisturb, MailOutline, MarkunreadMailboxOutlined } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { getTimezone } from 'utils';
import { CampaignChannels, SplitActions } from 'domains/campaigns/types';
import { getIsRecurrenceContinuous, getIsRecurrenceEnabled } from 'domains/campaigns/utils';
import { Template } from 'domains/content/types';
import useTemplates from 'hooks/queries/useTemplates';
import { CustomCampaignSteps } from '../../CustomCampaign';
import CustomCampaignNavigationButton from '../CustomCampaignNavigationButton';
import { CampaignSend } from '../../customCampaignReducer';

type Props = {
    activeSendIndex: number;
    activeStep: CustomCampaignSteps;
    index: number;
    send: CampaignSend;
    setActiveSendIndex: (index: number) => void;
    setActiveStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignNavigationSend = ({
    activeSendIndex,
    activeStep,
    index,
    send,
    setActiveSendIndex,
    setActiveStep,
}: Props) => {
    const { data: templates } = useTemplates();

    const isEmailSend = send.channel === CampaignChannels.EMAIL;
    const isDirectMailSend = send.channel === CampaignChannels.DIRECT_MAIL;
    const isSplitSendWithoutAction = isEmailSend && !!send.splitType && !send.splitAction;

    if (isSplitSendWithoutAction)
        return (
            <CustomCampaignNavigationButton
                disabled={!send.splitAction}
                isActive={activeStep === CustomCampaignSteps.SELECT_PATH_ACTION && index === activeSendIndex}
                icon={<CallSplit />}
                label={send.splitType}
                onClick={() => {
                    setActiveSendIndex(index);
                    setActiveStep(CustomCampaignSteps.SELECT_PATH_ACTION);
                }}
            />
        );

    // These bools and helper funcs are used for both direct mail and email sends
    const isActiveSend =
        (activeStep === CustomCampaignSteps.SELECT_CONTENT || activeStep === CustomCampaignSteps.SCHEDULE_CONTENT) &&
        index === activeSendIndex;
    const shouldShowScheduleInfo = index === 0 && send?.scheduledTimestamp;
    const getStyledContentName = (name: string) => <em>{name}</em>;
    const getScheduledInfo = (timestamp: string) => {
        if (!timestamp) return;

        const dateTime = DateTime.fromISO(timestamp);
        const month = dateTime.toFormat('LLLL');
        const dayAndYear = dateTime.toFormat('dd, yyyy');
        const time = `${dateTime.toFormat('h:mm a')} ${getTimezone()}`;
        return (
            <span>
                {month} {dayAndYear} at {time}
            </span>
        );
    };

    if (isDirectMailSend && send.content?.id) {
        const getContentValue = () => {
            const styledContentName = getStyledContentName(send.content?.name);
            if (!shouldShowScheduleInfo) return styledContentName;
            const scheduledSendTime = getScheduledInfo(send.scheduledTimestamp);
            return (
                <span>
                    {styledContentName} on {scheduledSendTime}
                </span>
            );
        };
        const contentValue = getContentValue();

        return (
            <CustomCampaignNavigationButton
                isActive={isActiveSend}
                icon={<MarkunreadMailboxOutlined />}
                label="direct mail"
                onClick={() => {
                    setActiveSendIndex(index);
                    setActiveStep(CustomCampaignSteps.SELECT_CONTENT);
                }}
                value={contentValue}
            />
        );
    }

    // We want to render a "send" in the nav if there is a send (template has been selected)
    // or if a condtional split will have a send, even before a template has been selected
    if (isEmailSend && (send.templateId || send.splitAction === SplitActions.SEND)) {
        const currentTemplate = templates?.find((template: Template) => template.id === send?.templateId);

        const getEmailValue = () => {
            const styledTemplateName = getStyledContentName(currentTemplate?.name);
            if (!shouldShowScheduleInfo) return styledTemplateName;

            const isRecurrenceEnabled = getIsRecurrenceEnabled(send?.recurrenceFrequency);
            const scheduledSendTime = getScheduledInfo(send?.scheduledTimestamp);

            if (isRecurrenceEnabled) {
                const hasEndDate = !!send?.scheduledEndTimestamp;
                const scheduledSendEndTime = getScheduledInfo(send?.scheduledEndTimestamp);
                const isRecurrenceContinuous: boolean = getIsRecurrenceContinuous(send.recurrenceFrequency);
                const recurrenceFrequency = isRecurrenceContinuous ? 'continually' : send.recurrenceFrequency;
                const recurrenceInfo = (
                    <span>
                        {styledTemplateName} starting {scheduledSendTime} and recurring {recurrenceFrequency}
                    </span>
                );

                return hasEndDate ? (
                    <span>
                        {recurrenceInfo} until {scheduledSendEndTime}
                    </span>
                ) : (
                    recurrenceInfo
                );
            }

            return (
                <span>
                    {styledTemplateName} on {scheduledSendTime}
                </span>
            );
        };
        const emailValue = getEmailValue();

        return (
            <>
                <div>{send.splitType}</div>
                <CustomCampaignNavigationButton
                    isActive={isActiveSend}
                    icon={<MailOutline />}
                    label="email"
                    onClick={() => {
                        setActiveSendIndex(index);
                        setActiveStep(
                            send.splitType ? CustomCampaignSteps.SELECT_PATH_ACTION : CustomCampaignSteps.SELECT_CONTENT
                        );
                    }}
                    value={emailValue}
                />
            </>
        );
    }

    if (isEmailSend && send.splitAction === SplitActions.EXIT)
        return (
            <>
                <div>{send.splitType}</div>
                <CustomCampaignNavigationButton
                    isActive={activeStep === CustomCampaignSteps.SELECT_PATH_ACTION && index === activeSendIndex}
                    icon={<DoNotDisturb />}
                    label="exit curation"
                    onClick={() => {
                        setActiveSendIndex(index);
                        setActiveStep(CustomCampaignSteps.SELECT_PATH_ACTION);
                    }}
                />
            </>
        );
};

export default CustomCampaignNavigationSend;
