import { UseQueryOptions } from 'react-query';
import { SenderProfile } from 'domains/content/types';
import { CuredApiPaths } from 'models/enums';
import useQuery from '../useQuery';
import QueryKeys from './keys';

type UseSenderProfilesParams = {
    reactQueryOptions?: UseQueryOptions<SenderProfile[], Error>;
    type?: 'direct-mail';
};

/**
 * Returns a react query hook containing the sender profiles data.
 * @param type - Optional 'direct-mail' string to filter sender profiles.
 * @param reactQueryOptions - Optional react-query options.
 * @returns A react query hook with sender profiles data.
 */
const useSenderProfiles = ({ reactQueryOptions, type }: UseSenderProfilesParams = {}) =>
    useQuery<SenderProfile[]>(
        QueryKeys.SENDER_PROFILES(type),
        `${CuredApiPaths.SENDER_PROFILES}${type ? `/${type}` : ''}`,
        reactQueryOptions
    );

export default useSenderProfiles;
