import { UseMutationOptions } from 'react-query';
import { ApiMethods, CuredApiPaths } from 'models/enums';
import useMutation from '../useMutation';
import MutationKeys from './keys';

type UploadPdfResponse = { contentId: number; message: string };

const METHOD = ApiMethods.POST;
const AUTHENTICATED = true;
const HEADERS = {
    'Content-Type': 'multipart/form-data',
};

/**
 * Uploads a PDF file.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useUploadPdf = (mutateOptions?: UseMutationOptions<UploadPdfResponse, Error, FormData>) =>
    useMutation<FormData, UploadPdfResponse>(
        MutationKeys.UPLOAD_PDF,
        CuredApiPaths.DIRECT_MAIL_SAVE_PDF,
        mutateOptions,
        METHOD,
        AUTHENTICATED,
        HEADERS
    );

export default useUploadPdf;
