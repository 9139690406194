import { DirectMailSenderProfile } from 'domains/content/types';

export const formatAddressLabel = (profile: DirectMailSenderProfile) => {
    const { firstName, lastName, companyName, streetAddress, streetAddress2, city, stateCode, zipCode } = profile;

    const senderName = !!firstName || !!lastName ? `${firstName} ${lastName}` : '';
    const getSenderFormatted = () => {
        if (!!senderName && !!companyName) return `${senderName}, ${companyName} `;
        if (!!senderName && !companyName) return `${senderName} `;
        if (!senderName && !!companyName) return `${companyName} `;
        return;
    };
    const senderFormatted = getSenderFormatted();
    const streetAddress2Formatted = streetAddress2 ? ` ${streetAddress2}` : '';

    return `${senderFormatted}<${streetAddress}${streetAddress2Formatted}, ${city}, ${stateCode} ${zipCode}>`;
};
