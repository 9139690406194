import { Box, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { DateTime } from 'luxon';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import InputLabel from 'domains/core/components/InputLabel';
import { SendRecurrenceFrequency } from 'domains/campaigns/types';

export const disabledMessage = 'Recommended send times are not available for continual sends.';
const defaultTooltipMessage =
    'Cured uses advanced analytics to generate a send time recommendation based on aggregate data from actual healthcare customer sends.';

export type Props = {
    isChecked: boolean;
    isDisabled: boolean;
    /**
     * Whether the form this switch in has been touched. If the form has not been touched the snackbar should not be shown.
     * This would happen if the user navigated away from the form then back and a new instance of this component was rendered.
     */
    isTouched: boolean;
    recurrenceFrequency?: SendRecurrenceFrequency;
    sendDate: DateTime;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RecommendedTimeSwitch = ({
    isChecked,
    isDisabled,
    isTouched,
    recurrenceFrequency,
    sendDate,
    onChange,
}: Props) => {
    const isDisabledByContinuousRecurrence = recurrenceFrequency === SendRecurrenceFrequency.CONTINUOUS;

    const renderInfoSnackbar = () => {
        const isUsingRecurrenceRecommendedTime =
            recurrenceFrequency === SendRecurrenceFrequency.EVERY_WEEKDAY ||
            recurrenceFrequency === SendRecurrenceFrequency.DAILY;

        if (
            isChecked &&
            isTouched &&
            sendDate &&
            !isUsingRecurrenceRecommendedTime &&
            !isDisabledByContinuousRecurrence
        ) {
            return (
                <InfoSnackbar
                    key={sendDate.toString()}
                    successMessage={
                        <Typography color="info.content" component="span" variant="body2">
                            Recommended time updated based on
                            <Typography color="info.dark" component="span" variant="body2">
                                {` ${sendDate.toFormat('MM/dd/yyyy')} send date`}
                            </Typography>
                            .
                        </Typography>
                    }
                />
            );
        }
    };

    const isRecommendedTimeDisabled = isDisabled || isDisabledByContinuousRecurrence || !sendDate;

    return (
        <>
            {renderInfoSnackbar()}
            <FormControlLabel
                control={
                    <Tooltip title={isDisabledByContinuousRecurrence && disabledMessage}>
                        <Box>
                            <Switch
                                color="secondary"
                                size="small"
                                checked={(!isDisabledByContinuousRecurrence && isChecked) ?? false}
                                disabled={isRecommendedTimeDisabled}
                                onChange={onChange}
                                sx={{ mx: 1 }}
                            />
                        </Box>
                    </Tooltip>
                }
                label={
                    <InputLabel
                        label={
                            <Typography variant="body2" color={isDisabledByContinuousRecurrence && 'text.disabled'}>
                                Use Cured's Recommended Time
                            </Typography>
                        }
                        labelIcon={
                            <InfoOutlined
                                color={isDisabledByContinuousRecurrence ? 'disabled' : 'inherit'}
                                fontSize="inherit"
                            />
                        }
                        labelIconTooltipText={defaultTooltipMessage}
                    />
                }
                sx={{ cursor: isRecommendedTimeDisabled ? 'default' : 'pointer' }}
            />
        </>
    );
};
export default RecommendedTimeSwitch;
