import { CuredApiPaths } from 'models/enums';
import { UseMutationOptions } from 'react-query';
import MutationKeys from './keys';
import useMutation from 'hooks/useMutation';

export type MoveContentPostBody = {
    id: number;
    type: string;
    currFolderId: number;
    targetFolderId: number;
};

export type MoveContentResponse = {
    targetFolderId: number | null;
};

/**
 * Move content from one folder to another.
 * @param mutateOptions react-query useMutation option
 * @returns the react-query useMutation results
 * See https://react-query-v3.tanstack.com/reference/useMutation for options and return value
 */
const useMoveContent = (mutateOptions?: UseMutationOptions<null, Error, MoveContentPostBody>) => {
    const { mutate: moveContent, isError, isLoading, isSuccess, data } = useMutation<MoveContentPostBody, null>(
        MutationKeys.MOVE_CONTENT,
        CuredApiPaths.CONTENT_MOVE,
        mutateOptions
    );

    return { moveContent, isError, isLoading, isSuccess, data };
};

export default useMoveContent;
