import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { CampaignChannels } from 'domains/campaigns/types';
import EmailContentStep from './EmailContentStep/EmailContentStep';
import DirectMailContentStep from './DirectMailContentStep';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    contentPreviewId?: number;
    dispatchCampaignAction: React.Dispatch<any>;
    setContentPreviewId: (id: number) => void;
    setIsContinueEnabled: (isContinueEnabled: boolean) => void;
    setNextStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignContentStep = ({
    activeSendIndex,
    campaignState,
    contentPreviewId,
    dispatchCampaignAction,
    setContentPreviewId,
    setIsContinueEnabled,
    setNextStep,
}: Props) => {
    const currentSend = campaignState.sends[activeSendIndex];
    const { channel } = currentSend;

    if (channel === CampaignChannels.EMAIL) {
        return (
            <EmailContentStep
                activeSendIndex={activeSendIndex}
                campaignState={campaignState}
                contentPreviewId={contentPreviewId}
                dispatchCampaignAction={dispatchCampaignAction}
                setContentPreviewId={setContentPreviewId}
                setIsContinueEnabled={setIsContinueEnabled}
                setNextStep={setNextStep}
            />
        );
    }

    if (channel === CampaignChannels.DIRECT_MAIL) {
        return (
            <DirectMailContentStep
                activeSendIndex={activeSendIndex}
                campaignState={campaignState}
                contentPreviewId={contentPreviewId}
                dispatchCampaignAction={dispatchCampaignAction}
                setContentPreviewId={setContentPreviewId}
                setIsContinueEnabled={setIsContinueEnabled}
                setNextStep={setNextStep}
            />
        );
    }
};

export default CustomCampaignContentStep;
