import { useHistory } from 'react-router';
import { ContactPageOutlined, SwapCallsSharp } from '@mui/icons-material';
import { Box, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import Button from 'domains/core/components/Button';
import SparkleIcon from 'domains/core/components/SvgIcons/SparkleIcon';
import { URLPaths } from 'models/enums';
import { getTimeDependentGreeting, getUserFriendlyDate } from 'utils';
import useContactsFileUploadProps from 'domains/contacts/hooks/useContactsFileUploadProps';
import ContactsUploadDialog from 'domains/contacts/components/ContactsUploadDialog';
import Article from './Article';

export const HomePage = () => {
    const history = useHistory();

    const hiddenMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const fileUploadProps = useContactsFileUploadProps();

    return (
        <>
            <ContactsUploadDialog {...fileUploadProps} />
            <Box display="flex" justifyContent="center" height="100%" width="100%">
                <Grid container sx={{ height: '100%' }}>
                    <Grid item sm={12} md={5} lg={6} pt={8} pr={6}>
                        <Stack rowGap={4} mb={8}>
                            <Stack direction="row" alignItems="center" columnGap={1}>
                                <Box color="action.active" mt={1}>
                                    <SparkleIcon />
                                </Box>
                                <Typography variant="caption" color="primary.main">
                                    {getUserFriendlyDate(new Date())}
                                </Typography>
                            </Stack>
                            <Typography variant="h3" mt={-2}>
                                {getTimeDependentGreeting(new Date())}
                            </Typography>
                            <Typography variant="body1">
                                We're so glad you're here!
                                <br />
                                Our mission to bring care full circle starts with improving connections with your
                                customers.
                            </Typography>
                            <Typography variant="body1">
                                Check out the suggested steps below to get the most out of Cured.
                            </Typography>
                        </Stack>
                        <Grid container mb={4}>
                            <Grid item xs={12} mb={2}>
                                <Typography variant="caption" color="action.active">
                                    Up Next
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={7}>
                                <Typography variant="body2" mr={2} mb={2}>
                                    Stay connected to your contacts with frequent and targeted interactions.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <Stack alignItems="flex-end" justifyContent="center">
                                    <Button
                                        variant="contained"
                                        onClick={() => history.push(URLPaths.CURATIONS_LANDING)}
                                        endIcon={<SwapCallsSharp fontSize="inherit" />}
                                    >
                                        Create Curation
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6} md={7}>
                                <Typography variant="body2" mr={2}>
                                    Keep your audiences up-to-date by regularly uploading your contacts data.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        data-testid="homepage-upload-button"
                                        endIcon={<ContactPageOutlined />}
                                        onClick={() => fileUploadProps.setIsFileUploadDialogOpen(true)}
                                        size="medium"
                                        variant="contained"
                                    >
                                        Upload Contacts
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!hiddenMdDown && (
                        <Grid item sm={12} md={7} lg={6}>
                            <Article />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default HomePage;
