import { Typography } from '@mui/material';
import Alert from 'domains/core/components/Alert';

type AlertMessageProps = {
    isError?: boolean;
    text?: string;
};

const AlertMessage = ({ isError, text }: AlertMessageProps) => {
    if (!text) return null;

    return (
        <Alert elevation={0} severity={isError ? 'error' : 'success'} variant="standard">
            <Typography color={isError ? 'error.dark' : 'success.dark'} variant="body2">
                {text}
            </Typography>
        </Alert>
    );
};

export default AlertMessage;
