import useContentQueryManager from 'hooks/useContentQueryParams';
import { useHistory } from 'react-router';
import { getContentCreatePath } from './ContentTable/utils';

const useGetCreateContentDropdownOptions = () => {
    const history = useHistory();
    const { folderId: parentFolderId } = useContentQueryManager();

    return [
        {
            label: 'email',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: true, parentFolderId });
                history.push(path);
            },
        },
        {
            label: 'landing page',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: false, parentFolderId });
                history.push(path);
            },
        },
    ];
};

export default useGetCreateContentDropdownOptions;
