import { Campaign, CampaignChannels, SendRecurrenceFrequency } from 'domains/campaigns/types';
import ContentDetailItem from 'domains/content/components/ContentDetail';
import DetailItem from 'domains/core/components/DetailItem';

export type Detail = {
    // An action or an email
    header?: string;
    // An action
    description?: string;
    // An email
    contentNodeId?: string;
    contentId?: number;
    parentAudienceDelayInterval?: string;
    recurrenceFrequency?: SendRecurrenceFrequency;
    scheduledEndTimestamp?: string;
    scheduledTimestamp?: string;
    onClick?: (contentNodeId: string) => void;
};

type CampaignDetailsProps = {
    channel?: Campaign['channel'];
    contentPreviewNodeId?: string | number;
    details: Detail[];
    isCampaign?: boolean;
};

const CampaignDetails = ({ channel, contentPreviewNodeId, details, isCampaign }: CampaignDetailsProps) => {
    const renderDetail = (detail: Detail, index: number) => {
        const isContent = !!detail?.contentId;

        if (isContent) {
            const isSMS = channel === CampaignChannels.SMS;

            const getHeader = () => {
                if (isSMS) {
                    return channel;
                }
                return detail.header ?? CampaignChannels.EMAIL.toLowerCase();
            };

            const isPreviewOpen = contentPreviewNodeId && detail?.contentNodeId === contentPreviewNodeId;

            const onClick = () => {
                isPreviewOpen ? detail?.onClick(null) : detail?.onClick(detail.contentNodeId);
            };

            return (
                <ContentDetailItem
                    key={detail.contentId + index}
                    contentId={detail.contentId}
                    firstEmailRecurrenceFrequency={details[0].recurrenceFrequency}
                    header={getHeader()}
                    isCampaign={isCampaign}
                    isFirstEmail={index === 0}
                    isPreviewOpen={isPreviewOpen}
                    isSMS={isSMS}
                    recurrenceFrequency={detail.recurrenceFrequency}
                    scheduledEndTimestamp={detail.scheduledEndTimestamp}
                    scheduledTimestamp={detail.scheduledTimestamp}
                    timeDelay={detail.parentAudienceDelayInterval}
                    onClick={detail.onClick ? onClick : null}
                />
            );
        }

        return (
            <DetailItem
                key={detail.header + detail.description + index}
                header={detail.header}
                description={detail.description}
            />
        );
    };

    return <>{details?.map((detail, index) => renderDetail(detail, index))}</>;
};

export default CampaignDetails;
