import { Skeleton, Stack } from '@mui/material';
import { useRef } from 'react';
import Button from 'domains/core/components/Button';
import DeleteButton from 'domains/core/components/DeleteButton';

type FileUploaderProps = {
    fileName: string;
    isUploadPdfLoading: boolean;
    onFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveUploadButtonClick: () => void;
};

const FileUploader = ({
    fileName,
    isUploadPdfLoading,
    onFileInputChange,
    onRemoveUploadButtonClick,
}: FileUploaderProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const uploadButtonText = isUploadPdfLoading ? <Skeleton width="100%" /> : fileName || 'Upload From Computer';

    const handleUploadButtonClick = () => fileInputRef.current?.click();

    return (
        <Stack direction="row" alignItems="center" columnGap={1}>
            <input
                accept="application/pdf"
                onChange={onFileInputChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
            />
            <Button
                disabled={isUploadPdfLoading}
                fullWidth
                onClick={handleUploadButtonClick}
                sx={{
                    justifyContent: 'flex-start',
                    textTransform: 'none',
                }}
                variant="outlined"
            >
                {uploadButtonText}
            </Button>
            {fileName && !isUploadPdfLoading && <DeleteButton onClick={onRemoveUploadButtonClick} />}
        </Stack>
    );
};

export default FileUploader;
