import { SwapCalls, ArrowBack } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router';
import { URLPaths } from 'models/enums';
import ssImage from 'assets/images/svg/single-send-preview.svg';
import tdImage from 'assets/images/svg/time-delay-preview.svg';
import csImage from 'assets/images/svg/conditional-split-preview.svg';
import customImage from 'assets/images/svg/custom-curation-preview.svg';
import { CampaignChannels, CampaignKey } from 'domains/campaigns/types';
import { getCampaignRoute } from 'domains/campaigns/state/utils';
import Button from 'domains/core/components/Button';
import UpdateTag from 'domains/core/components/UpdateTag';
import useFeatureSettings from 'hooks/queries/useFeatureSettings';
import CurationItem from './CurationItem';

const CurationLandingPage = () => {
    const history = useHistory();

    const { data: featureSettings, isSuccess: isFeatureSettingsSuccess } = useFeatureSettings();

    const { directMail: hasDirectMail, flexibleCurations: hasFlexibleCurations } = useFlags();

    const routeSingleSend = getCampaignRoute({ campaignKey: CampaignKey.SINGLE_SEND });
    const routeEmailSeries = getCampaignRoute({ campaignKey: CampaignKey.EMAIL_SERIES });

    const singleSendImage = <img src={ssImage} alt="" width="100%" />;
    const timeDelayImage = <img src={tdImage} alt="" width="100%" />;
    const conditionalSplitImage = <img src={csImage} alt="" width="100%" />;
    const customCurationImage = <img src={customImage} alt="" width="100%" />;

    const gridSizes = hasFlexibleCurations ? { xs: 12, md: 6, xl: 3 } : { xs: 12, sm: 6, lg: 4 };

    const renderCustomCurationOption = () => {
        const sharedProps = {
            imageSrc: customCurationImage,
            subtitle: 'Build your own communication flows',
            title: 'Custom Curation',
            updateTag: <UpdateTag tag="early release" tagSx={{ margin: 2 }} />,
        };
        const navigateToCustomCurationsPage = () =>
            history.push({
                pathname: URLPaths.CURATIONS_CUSTOM,
                state: { channel: CampaignChannels.EMAIL },
            });

        if (hasDirectMail) {
            return (
                <Grid item {...gridSizes}>
                    <CurationItem
                        options={[
                            {
                                label: 'Direct Mail',
                                onClick: () =>
                                    history.push({
                                        pathname: URLPaths.CURATIONS_CUSTOM,
                                        state: { channel: CampaignChannels.DIRECT_MAIL },
                                    }),
                            },
                            {
                                label: 'Email',
                                onClick: navigateToCustomCurationsPage,
                            },
                        ]}
                        {...sharedProps}
                    />
                </Grid>
            );
        }

        return (
            <Grid item {...gridSizes}>
                <CurationItem onClick={navigateToCustomCurationsPage} {...sharedProps} />
            </Grid>
        );
    };

    return (
        <>
            <Grid container direction="column" my={8}>
                <Grid container direction="column">
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" columnGap={4}>
                            <Stack direction="row" alignItems="end" columnGap={0.5}>
                                <SwapCalls fontSize="medium" color="action" />
                                <Typography variant="caption" color="primary.main">
                                    curations
                                </Typography>
                            </Stack>
                        </Stack>
                        <Button
                            variant="text"
                            startIcon={<ArrowBack />}
                            onClick={() => history.push(URLPaths.CURATIONS)}
                        >
                            Back
                        </Button>
                    </Stack>
                    <Typography variant="h3" mb={2}>
                        create better connections
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Typography>
                            Select a Curation below to easily reach your customers at the right time with the right
                            content.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={4} pb={4}>
                {hasFlexibleCurations && renderCustomCurationOption()}
                <Grid item {...gridSizes}>
                    <CurationItem
                        imageSrc={singleSendImage}
                        subtitle="Quick and direct communication with individuals"
                        title="Single Send"
                        onClick={() => history.push(routeSingleSend)}
                    />
                </Grid>
                <Grid item {...gridSizes}>
                    <CurationItem
                        imageSrc={timeDelayImage}
                        subtitle="Connected sends to encourage engagement"
                        title="Email Series"
                        onClick={() => history.push(routeEmailSeries)}
                    />
                </Grid>
                <Grid item {...gridSizes}>
                    <CurationItem
                        imageSrc={conditionalSplitImage}
                        subtitle="Focus communication based on engagement"
                        title="Conditional Split"
                        onClick={() => history.push(URLPaths.CURATIONS_CONDITIONAL_SPLIT)}
                    />
                </Grid>
                {isFeatureSettingsSuccess && featureSettings?.sms_enabled && (
                    <Grid item {...gridSizes}>
                        <CurationItem
                            imageSrc={singleSendImage}
                            subtitle="Efficient and streamlined communications with SMS messaging"
                            title="SMS"
                            onClick={() => history.push(URLPaths.CURATIONS_SMS)}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default CurationLandingPage;
