import { CustomCampaignSteps } from '../CustomCampaign';
import { CampaignStateTypes } from '../customCampaignReducer';
import CustomCampaignNavigationBlock from './CustomCampaignNavigationBlock';

type Props = {
    activeSendIndex: number;
    activeStep: CustomCampaignSteps;
    campaignState: CampaignStateTypes;
    onRemoveClick: (index: number) => void;
    setActiveSendIndex: (index: number) => void;
    setActiveStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignNavigation = ({
    activeSendIndex,
    activeStep,
    campaignState,
    onRemoveClick,
    setActiveSendIndex,
    setActiveStep,
}: Props) => (
    <>
        {campaignState.sends.map((send, index) => (
            <CustomCampaignNavigationBlock
                activeSendIndex={activeSendIndex}
                activeStep={activeStep}
                campaignState={campaignState}
                key={`${send.channel}-${index}`}
                index={index}
                onRemoveClick={onRemoveClick}
                send={send}
                setActiveSendIndex={setActiveSendIndex}
                setActiveStep={setActiveStep}
            />
        ))}
    </>
);

export default CustomCampaignNavigation;
