import { useEffect, useState } from 'react';
import { CustomCampaignSteps } from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import { addSend } from 'domains/campaigns/components/CustomCampaign/customCampaignActions';
import { CampaignStateTypes } from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { CampaignChannels } from 'domains/campaigns/types';
import { MAX_SENDS_AMOUNT, getSteps, renderChooseStepsForm } from '../utils';

type Props = {
    activeSendIndex: number;
    campaignState: CampaignStateTypes;
    dispatchCampaignAction: React.Dispatch<any>;
    setActiveSendIndex: (index: number) => void;
    setActiveStep: (step: CustomCampaignSteps) => void;
};

const CustomCampaignChooseNextStep = ({
    activeSendIndex,
    campaignState,
    dispatchCampaignAction,
    setActiveSendIndex,
    setActiveStep,
}: Props) => {
    const [selectedStep, setSelectedStep] = useState(null);

    const maxSendsReached = campaignState.sends.length === MAX_SENDS_AMOUNT;

    const getIsStepDisabled = (step: CustomCampaignSteps) => {
        const activeSend = campaignState.sends[activeSendIndex];
        const isActiveSendEmail = activeSend?.channel === CampaignChannels.EMAIL;
        const isCompletedConditionalSplit = isActiveSendEmail && !!activeSend.splitType && !!activeSend.splitAction;

        switch (step) {
            case CustomCampaignSteps.SELECT_CONTENT:
                return true;
            case CustomCampaignSteps.SET_TIME_DELAY:
                return isCompletedConditionalSplit || maxSendsReached;
            case CustomCampaignSteps.CONDITIONAL_SPLIT:
                return true;
            case CustomCampaignSteps.PUBLISH_CAMPAIGN:
                return false;
            default:
                return false;
        }
    };

    useEffect(() => {
        const isSelectedStepDisabled = getIsStepDisabled(selectedStep);
        if (isSelectedStepDisabled) return;

        if (selectedStep) setActiveStep(selectedStep);

        // We only want this to run when the send index or selected step change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex, selectedStep]);

    // Handles adding/configuring sends based on next step selection
    useEffect(() => {
        if (selectedStep === CustomCampaignSteps.SET_TIME_DELAY) {
            // Add a send when selecting "time delay" while on the last send
            const isLastSend = activeSendIndex === campaignState.sends.length - 1;
            isLastSend && dispatchCampaignAction(addSend(activeSendIndex));

            setActiveSendIndex(activeSendIndex + 1);
        }

        // We only want this to run when the send index or selected step change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSendIndex, selectedStep]);

    const handleStepClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedStep((event.target as HTMLInputElement).value);
    };

    const steps = getSteps(getIsStepDisabled, maxSendsReached);

    return renderChooseStepsForm(handleStepClick, selectedStep, steps);
};

export default CustomCampaignChooseNextStep;
