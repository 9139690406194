import { useState } from 'react';
import { FolderOpenOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Button from 'domains/core/components/Button';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import useContentWithFolders from 'hooks/queries/useContentWithFolders';
import useMoveContent, { MoveContentResponse } from 'hooks/mutations/useMoveContent';
import useContentQueryManager from 'hooks/useContentQueryParams';
import {
    Content,
    ContentType,
    ContentTypeEnumForBackend,
    FolderBreadcrumbData,
    RootFolder,
} from 'domains/content/types';
import { contentTypeToSnakeCase, extractContentId, isRootFolder, rootFolderId } from '../ContentTable/utils';
import ContentRow from './ContentRow';
import PreviewContent from './PreviewContent';

type Props = {
    activeContent: Content;
    activeFolder: FolderBreadcrumbData | typeof RootFolder;
    handleModalClose: () => void;
    isOpen: boolean;
    refetch: () => void;
    resetActiveContent: () => void;
};

const MoveContentModal = ({
    activeContent,
    activeFolder,
    handleModalClose,
    isOpen,
    refetch,
    resetActiveContent,
}: Props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [futureLocation, setFutureLocation] = useState(null);
    const [previewedLocation, setPreviewedLocation] = useState(null);

    const { changeViewParams } = useContentQueryManager();

    const { data: locationData } = useContentWithFolders({
        type: ContentType.FOLDER,
        reactQueryOptions: { enabled: !!activeContent && isOpen },
    });

    const { data: folderPreviewData } = useContentWithFolders({
        folderId: previewedLocation?.id,
        reactQueryOptions: { enabled: !!previewedLocation },
    });

    const handleModalCloseAndReset = () => {
        setFutureLocation(null);
        setPreviewedLocation(null);
        resetActiveContent();
        handleModalClose();
    };

    const onError = (error: Error) => {
        setErrorMessage(error.message);
        handleModalCloseAndReset();
    };

    const onSuccess = (response: MoveContentResponse) => {
        setSuccessMessage(`"${activeContent?.name}" successfully moved to "${futureLocation?.name}."`);

        const { targetFolderId } = response;
        refetch();
        handleModalCloseAndReset();
        changeViewParams({ newFolderId: targetFolderId === null ? rootFolderId : targetFolderId });
    };

    const { moveContent, isLoading, isSuccess } = useMoveContent({ onError, onSuccess });

    const handleMoveContent = () => {
        const contentToMoveID = extractContentId(activeContent.id);
        const contentToMoveType = contentTypeToSnakeCase(activeContent.type as keyof typeof ContentTypeEnumForBackend);

        const requestBody = {
            id: contentToMoveID,
            type: contentToMoveType,
            ...(futureLocation.id !== rootFolderId && {
                targetFolderId: futureLocation.id,
            }),
            ...(activeFolder.id !== rootFolderId && {
                currFolderId: activeFolder?.id,
            }),
        };
        moveContent(requestBody);
    };

    const renderContentTable = () => {
        if (!!previewedLocation) {
            return (
                <PreviewContent
                    data={folderPreviewData}
                    previewedLocation={previewedLocation}
                    resetPreview={() => {
                        setPreviewedLocation(null);
                        setFutureLocation(null);
                    }}
                />
            );
        }

        const getIsRowSelected = (location: Content) => location?.id === futureLocation?.id;

        return (
            <Stack sx={{ padding: '1rem 1rem 0' }}>
                <ContentRow
                    activeFolder={activeFolder}
                    isRowSelected={getIsRowSelected(RootFolder as Content)}
                    setFutureLocation={setFutureLocation}
                />
                {locationData?.map((location) => (
                    <ContentRow
                        key={location.id}
                        activeFolder={activeFolder}
                        content={location}
                        isRowSelected={getIsRowSelected(location)}
                        setFutureLocation={setFutureLocation}
                        setPreviewedLocation={setPreviewedLocation}
                    />
                ))}
            </Stack>
        );
    };

    const renderCurrentLocation = () => {
        const Icon = isRootFolder(activeFolder?.id) ? ContentIcon : FolderOpenOutlined;

        return (
            <Typography variant="body2" sx={{ padding: '0 2rem 1rem' }}>
                Current Location:
                <strong>
                    <Icon sx={{ margin: '0 4px -6px 16px' }} /> {activeFolder?.name}
                </strong>
            </Typography>
        );
    };

    const infoMessage = `Moving "${activeContent?.name}" to "${futureLocation?.name}"`;

    return (
        <>
            {errorMessage && <ErrorSnackbar errorMessage={errorMessage} />}
            {isLoading && <InfoSnackbar successMessage={infoMessage} />}
            {isSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={handleModalCloseAndReset}>
                <DialogTitle>Move "{activeContent?.name}"</DialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    {renderCurrentLocation()}
                    {renderContentTable()}
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleModalCloseAndReset}>
                        Cancel
                    </Button>
                    <Button disabled={!futureLocation} variant="contained" onClick={handleMoveContent}>
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default MoveContentModal;
