import CustomCampaign from 'domains/campaigns/components/CustomCampaign/CustomCampaign';
import {
    customCurationReducer,
    directMailInitialState,
    emailInitialState,
} from 'domains/campaigns/components/CustomCampaign/customCampaignReducer';
import { CampaignChannels } from 'domains/campaigns/types';
import { useReducer } from 'react';
import { useHistory } from 'react-router';

const CustomPage = () => {
    const history: { location?: { state?: { channel?: CampaignChannels } } } = useHistory();
    const channel = history?.location?.state?.channel ?? CampaignChannels.EMAIL;

    const getInitialState = () => {
        if (channel === CampaignChannels.DIRECT_MAIL) return directMailInitialState;
        if (channel === CampaignChannels.EMAIL) return emailInitialState;
    };
    const initialState = getInitialState();

    const [state, dispatch] = useReducer(customCurationReducer, initialState);

    return <CustomCampaign campaignState={state} dispatchCampaignAction={dispatch} />;
};

export default CustomPage;
