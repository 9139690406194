import { AutoAwesome } from '@mui/icons-material';
import { CampaignChannels } from 'domains/campaigns/types';
import FormLayout from 'domains/core/components/FormLayout';
import { CampaignStateTypes } from '../../../customCampaignReducer';

type Props = {
    campaignState: CampaignStateTypes;
};

const CustomCampaignPublishStep = ({ campaignState }: Props) => {
    const isDirectMailSend = campaignState.sends[0].channel === CampaignChannels.DIRECT_MAIL;

    const guidingText = `Take this moment to confirm the details of your Curation. ${
        isDirectMailSend
            ? 'Once the Curation is published, the files will start printing the business day after the schedule date. '
            : ''
    }While you can always cancel upcoming sends, please note that you cannot take back any that have already been sent.`;

    return (
        <FormLayout header="last step!" stepName="publish" icon={<AutoAwesome />} guidingText={guidingText}>
            <></>
        </FormLayout>
    );
};

export default CustomCampaignPublishStep;
