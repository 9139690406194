import { Box, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import useQueryParams from 'hooks/useQueryParams';
import useCreateTemplate from 'hooks/mutations/useCreateTemplate';
import useUpdateTemplate from 'hooks/mutations/useUpdateTemplate';
import useBeeConfig from 'hooks/queries/useBeeConfig';
import useTemplate from 'hooks/queries/useTemplate';
import { BeeConfigType, URLPaths } from 'models/enums';
import Alert from 'domains/core/components/Alert';
import Button from 'domains/core/components/Button';
import TextField from 'domains/core/components/TextField';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import { isTemplate as isTemplatePageResponse } from 'domains/content/types';
import BeeEditor from 'domains/content/components/Bee/BeeEditor';
import BeeEditorTestSendDialog from 'domains/content/components/Bee/BeeEditorTestSendDialog';
import useBeeAutoSave from 'domains/content/components/Bee/useBeeAutoSave';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getContentCreatePath } from 'pages/ContentPage/ContentTable/utils';

const buttonCopy = {
    color: theme.palette.warning.content,
    backgroundColor: 'theme.palette.warning.background',
    marginLeft: theme.spacing(1),
};

const getNameHelperText = (isNameUndefinedOnTouch: boolean, isNameTooShort: boolean) => {
    if (isNameUndefinedOnTouch) {
        return 'Please enter an email name';
    }

    if (isNameTooShort) {
        return 'Email name must have at least 3 characters';
    }
};

const ContentEmailBuilderPage = () => {
    const [name, setName] = useState('');
    const [isNameTouched, setIsNameTouched] = useState(false);
    const [isTestSendDialogOpen, setIsTestSendDialogOpen] = useState(false);
    /**
     * Bee-specific states, hooks, and functions
     */
    const [beeJson, setBeeJson] = useState('');
    const [isNoRestart, setIsNoRestart] = useState(false);
    const [isSaveNameClicked, setIsSaveNameClicked] = useState(false);
    const [isSaveBeeJsonClicked, setIsSaveBeeJsonClicked] = useState(false);
    const { contentFolder: hasContentFoldersFeature } = useFlags();

    const history = useHistory();

    const query = useQueryParams();
    const queryClient = useQueryClient();
    const id = query.get('id');
    const parentFolderId = Number(query.get('parentFolderId'));

    const onSuccess = (data: any) => {
        queryClient.invalidateQueries({
            // When we update a template, the cached network calls will be stale for the updated templates, so we need to invalidate the cached GET requests.
            predicate: (query: any) => {
                const firstQueryKey: string = query.queryKey[0];
                const invalidate = firstQueryKey === 'template';
                return invalidate;
            },
        });

        // Reset to prevent useBeeAutoSave from running onUpdate() on every name change
        setIsSaveNameClicked(false);

        // If creating or copying a template, we need to change the route to transition to "edit" mode.
        if (isCreate || isCopy) {
            setIsNoRestart(true);
            history.push(getContentCreatePath({ isEmail: true, id: data.id, parentFolderId }));
        }
        // If save was clicked by the user (not autosaved), enabled the nav, and navigate back to the content page with email type.
        if (isSaveBeeJsonClicked) {
            if (hasContentFoldersFeature) {
                history.push(`${URLPaths.CONTENT}${parentFolderId ? `/${parentFolderId}` : ''}`);
            } else {
                history.push(`${URLPaths.CONTENT}?type=email`);
            }
        }
    };

    const { error: saveError, isError: isSaveError, mutate: saveTemplate } = useCreateTemplate({
        onSuccess,
    });

    const { error: updateError, isError: isUpdateError, mutate: updateTemplate } = useUpdateTemplate(id, {
        onSuccess,
    });

    const {
        data: templateQuery,
        error: templateError,
        isError: isTemplateError,
        isSuccess: isTemplateSuccess,
    } = useTemplate({ templateId: id });

    const isTemplate = isTemplateSuccess && isTemplatePageResponse(templateQuery);

    const template = isTemplate ? JSON.parse(templateQuery.beeJson) : templateQuery;

    // If the isCopy flag is set to true, the content create page saves a new template
    const isCopy = query.get('isCopy') === 'true';
    const isCreate = !isCopy && !isTemplate;
    const isUpdate = !isCopy && isTemplate;
    const isLayout = !isCopy && isTemplate && templateQuery.isLayout;
    const isError = isTemplateError || isSaveError || isUpdateError;

    const isSaveClicked = isSaveNameClicked || isSaveBeeJsonClicked;
    const isNameUndefinedOnSave = !name && isSaveClicked;
    const isNameUndefinedOnTouch = !name && isNameTouched;
    const isNameTooShort = isNameTouched && name.length < 3;

    const { trackChange } = useBeeAutoSave({
        beeJson,
        id,
        isNameTooShort,
        isSaveClicked,
        isTemplate: true,
        isTemplateLayout: isLayout,
        isUpdate,
        name,
        onSave: saveTemplate,
        onUpdate: updateTemplate,
        parentFolderId,
    });

    const { data: beeConfig, isSuccess: isBeeConfigSuccess } = useBeeConfig(BeeConfigType.EMAIL, { cacheTime: 0 });

    // Update the template name local state when the template loads.
    useEffect(() => {
        if (isTemplate && isCopy) {
            setName(`Copy of ${templateQuery.name}`);
        } else if (isTemplate && !isCopy) {
            setName(templateQuery.name);
        } else if (!isNoRestart) {
            setName('Untitled');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTemplateSuccess]);

    /**
     * Handles the autosave event. We are not using Bee's autosave callback because the only variable we have access to is the json
     * and we need to check to see if there are unsaved changes and make the post with the name, and those variables are stale when the callback is invoked.
     */
    const handleAutoSave = (beeJson: string) => {
        setBeeJson(beeJson);
    };

    const handleSaveBeeJson = (beeJson: string) => {
        setBeeJson(beeJson);
        setIsSaveBeeJsonClicked(true);
    };

    const handleChangeBeeEditor = (beeJson: string, response: object) => {
        setBeeJson(beeJson);
        trackChange(response);
    };

    const handleChangeName = (e: any) => {
        if (!isNameTouched) {
            setIsNameTouched(true);
        }
        setName(e.target.value);
    };

    const handleSaveName = () => {
        const getJson = () => {
            if (beeJson) {
                return beeJson;
            }
            if (isTemplate) {
                return templateQuery.beeJson;
            }
            return JSON.stringify(templateQuery);
        };
        setBeeJson(getJson());
        setIsSaveNameClicked(true);
    };

    const handleClickCopy = () => {
        setName(`Copy of ${name}`);
        // Pass the isCopy flag. If the flag isn't passed, the value for isCopy defaults to false.
        history.push(`${URLPaths.CONTENT_CREATE_EMAIL}?id=${id}&isCopy=true`);
    };

    const handleCloseTestSendDialog = () => setIsTestSendDialogOpen(false);

    return (
        <Stack height="100%" mb={4} rowGap={4}>
            <div style={{ padding: '8px 0' }}>
                <TextField
                    data-testid="name-input"
                    disabled={isLayout}
                    error={isNameUndefinedOnTouch || isNameTooShort}
                    helperText={getNameHelperText(isNameUndefinedOnTouch, isNameTooShort)}
                    label="Email Name"
                    required
                    value={name}
                    width="20%"
                    withClickAway
                    onChange={handleChangeName}
                    onClickAway={handleSaveName}
                />
            </div>
            <BeeEditorTestSendDialog
                handleClose={handleCloseTestSendDialog}
                isOpen={isTestSendDialogOpen}
                templateId={Number(id)}
            />
            {(isError || isNameUndefinedOnSave) && (
                <ErrorSnackbar
                    errorMessage={
                        templateError?.message ||
                        saveError?.message ||
                        updateError?.message ||
                        'Your email must include a name.'
                    }
                />
            )}
            {isLayout && (
                <Box display="flex" flex={1} mb={1} justifyContent="center">
                    <Alert
                        elevation={0}
                        severity="warning"
                        variant="standard"
                        action={
                            <Button data-testid="copy-button" sx={buttonCopy} onClick={handleClickCopy}>
                                Copy
                            </Button>
                        }
                    >
                        Edits to this email will not be saved. Please make a copy of this email to save changes.
                    </Alert>
                </Box>
            )}
            <Stack height="100%">
                {isBeeConfigSuccess && (
                    <BeeEditor
                        beeConfig={beeConfig}
                        beeJson={template}
                        isNoRestart={isNoRestart}
                        isSuccess={isTemplateSuccess}
                        onAutoSave={handleAutoSave}
                        onChange={handleChangeBeeEditor}
                        onSave={handleSaveBeeJson}
                        openSendTestDialog={() => setIsTestSendDialogOpen(true)}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default ContentEmailBuilderPage;
