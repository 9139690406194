import { Close } from '@mui/icons-material';
import { SxProps, Theme, Tooltip } from '@mui/material';
import Button from 'domains/core/components/Button';

type Props = {
    onClick: () => void;
    onMouseLeave?: () => void;
    onMouseOver?: () => void;
    sx?: SxProps<Theme>;
    tooltipTitle?: string;
};

const DeleteButton = ({ onClick, onMouseLeave, onMouseOver, sx, tooltipTitle }: Props) => (
    <Tooltip title={tooltipTitle}>
        <Button
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
            sx={{
                height: 'unset',
                minWidth: 'unset',
                padding: '0',
                ...sx,
            }}
        >
            <Close />
        </Button>
    </Tooltip>
);

export default DeleteButton;
