import { Box } from '@mui/material';
import DeleteButton from 'domains/core/components/DeleteButton';
import { SegmentFilterCategorySpecificField, SegmentFilterState } from 'domains/segments/types';

type Props = {
    filter: SegmentFilterState<SegmentFilterCategorySpecificField>;
    index: number;
    isCategoryEvent: boolean;
    removeFilter: (index: number) => void;
    setIsFilterHighlighted: (value: boolean) => void;
    setIsOperatorValueHighlighted: (value: boolean) => void;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterCategorySpecificField>,
        resetPreview?: boolean
    ) => void;
};

const DeleteFilter = ({
    filter,
    index,
    isCategoryEvent,
    removeFilter,
    setIsFilterHighlighted,
    setIsOperatorValueHighlighted,
    updateSegmentFilter,
}: Props) => {
    const isCategorySet = Boolean(filter.category);
    const isOperatorSet = Boolean(filter.operator);

    const getOrder = () => {
        if (!isCategorySet) return '2';
        if (isOperatorSet) return '4';
        return '3';
    };
    const order = getOrder();

    return (
        <Box order={order} style={{ display: 'inherit', margin: '0 -12px 0 -8px' }}>
            <DeleteButton
                onClick={() => removeFilter(index)}
                onMouseLeave={() => setIsFilterHighlighted(false)}
                onMouseOver={() => setIsFilterHighlighted(true)}
                sx={{
                    svg: {
                        height: '20px',
                        width: '20px',
                    },
                }}
                tooltipTitle="Delete filter"
            />
        </Box>
    );
};

export default DeleteFilter;
