import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ContentType, ContentModalTypes, ContentModalContentType } from 'domains/content/types';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown';
import useContentQueryManager from 'hooks/useContentQueryParams';
import { extractContentId, getContentCreatePath, getContentType } from './utils';

type Props = {
    isTemplate: boolean;
    handleModalOpen: (content: ContentModalContentType, modalType: ContentModalTypes) => void;
    val: any;
};

const useGetMoreOptionsCell = ({ isTemplate, handleModalOpen, val }: Props) => {
    const { contentFolder: hasContentFoldersFeature } = useFlags();

    const history = useHistory();

    const { changeViewParams } = useContentQueryManager();

    const contentType = getContentType(val.row.original, hasContentFoldersFeature);
    const { folderId: parentFolderId } = useContentQueryManager();

    const handleDeleteModalOpen = (contentData: ContentModalContentType) =>
        handleModalOpen(contentData, ContentModalTypes.DELETE);

    const shouldShowDeleteOption = !isTemplate || val.row.original.canDelete;

    let menuItems;
    if (!hasContentFoldersFeature) {
        const editOption = {
            name: 'Edit',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: isTemplate, id: val.row.values.id });
                history.push(path);
            },
        };
        const copyOption = {
            name: 'Copy',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: isTemplate, id: val.row.values.id, isCopy: true });
                history.push(path);
            },
        };
        const deleteOption = {
            name: 'Delete',
            onClick: () => handleDeleteModalOpen({ ...val.row.original, type: contentType }),
        };

        menuItems = shouldShowDeleteOption ? [editOption, copyOption, deleteOption] : [editOption, copyOption];

        return <MoreOptionsDropdown menuItems={menuItems} />;
    }

    const contentId = extractContentId(val.row.original.id);
    const contentData = {
        ...val.row.original,
        id: contentId,
    };

    const deleteOption = {
        name: 'Delete',
        onClick: () => handleDeleteModalOpen(contentData),
    };

    if (val.row.original.entityType === ContentType.FOLDER) {
        const openOption = {
            name: 'Open',
            onClick: () => changeViewParams({ newFolderId: contentId }),
        };
        const renameOption = {
            name: 'Rename',
            onClick: () => handleModalOpen(contentData, ContentModalTypes.RENAME),
        };
        menuItems = val.row.original.canDelete ? [openOption, renameOption, deleteOption] : [openOption, renameOption];
    } else {
        const editOption = {
            name: 'Edit',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: isTemplate, id: contentId, parentFolderId });
                history.push(path);
            },
        };
        const copyOption = {
            name: 'Copy',
            onClick: () => {
                const path = getContentCreatePath({ isEmail: isTemplate, id: contentId, isCopy: true, parentFolderId });
                history.push(path);
            },
        };
        const moveOption = {
            name: 'Move',
            onClick: () => handleModalOpen(val.row.original, ContentModalTypes.MOVE),
        };

        menuItems = shouldShowDeleteOption
            ? [editOption, copyOption, moveOption, deleteOption]
            : [editOption, moveOption, copyOption];
    }

    return <MoreOptionsDropdown menuItems={menuItems} />;
};

export default useGetMoreOptionsCell;
