import { AccessTime, CallSplit, Close, SupervisedUserCircleOutlined } from '@mui/icons-material';
import theme from 'theme';
import { CampaignChannels, SplitTypes } from 'domains/campaigns/types';
import IconButton from 'domains/core/components/IconButton';
import { Segment } from 'domains/segments/types';
import useSegments from 'hooks/queries/useSegments';
import { CustomCampaignSteps } from '../../CustomCampaign';
import { CampaignSend, CampaignStateTypes } from '../../customCampaignReducer';
import CustomCampaignNavigationSend from '../CustomCampaignNavigationSend';
import CustomCampaignNavigationButton from '../CustomCampaignNavigationButton';

const sendBlockStyles = {
    alignItems: 'center',
    display: 'flex',
};

type Props = {
    activeSendIndex: number;
    activeStep: CustomCampaignSteps;
    campaignState: CampaignStateTypes;
    index: number;
    onRemoveClick: (index: number) => void;
    setActiveSendIndex: (index: number) => void;
    setActiveStep: (step: CustomCampaignSteps) => void;
    send: CampaignSend;
};

const CustomCampaignNavigationBlock = ({
    activeSendIndex,
    activeStep,
    campaignState,
    index,
    onRemoveClick,
    send,
    setActiveSendIndex,
    setActiveStep,
}: Props) => {
    const { data: audiences } = useSegments();

    const sendIsEmail = send.channel === CampaignChannels.EMAIL;
    const shouldShowAudienceButton = index === 0 && send.segmentId;
    const shouldShowTimeDelayButton =
        sendIsEmail && index > 0 && (!send.splitType || send.splitType === SplitTypes.MEETS);
    const shouldShowConditionalSplitButton = sendIsEmail && send.splitType === SplitTypes.MEETS;

    const isLastStep = index === campaignState.sends.length - 1;
    // TODO: update removing to account for conditional split
    const shouldShowRemoveButton = index > 0 && isLastStep && sendIsEmail && !send.splitType;

    const currentAudience = audiences?.find((audience: Segment) => audience.id === send.segmentId);

    const getTimeDelayValue = () => {
        const { parentAudienceDelayInterval } = sendIsEmail && send;
        if (!parentAudienceDelayInterval) return;

        const delayValue = Number(parentAudienceDelayInterval?.match(/\d+/)[0]);
        return `${parentAudienceDelayInterval}${delayValue > 1 ? 's' : ''}`;
    };

    const timeDelayValue = getTimeDelayValue();

    const sendWrapperStyles = {
        border: shouldShowRemoveButton ? `1px dashed ${theme.palette.primary.main}` : 'none',
        borderRadius: '8px',
        marginRight: '8px',
        width: '90%',
    };

    return (
        <div style={sendBlockStyles}>
            <div style={sendWrapperStyles}>
                {shouldShowAudienceButton && (
                    <CustomCampaignNavigationButton
                        isActive={activeStep === CustomCampaignSteps.SELECT_AUDIENCE}
                        icon={<SupervisedUserCircleOutlined />}
                        label="audience"
                        onClick={() => {
                            setActiveStep(CustomCampaignSteps.SELECT_AUDIENCE);
                            // Go back to the first send so that "continue" works as expected
                            setActiveSendIndex(0);
                        }}
                        value={currentAudience.name}
                    />
                )}
                {shouldShowTimeDelayButton && (
                    <CustomCampaignNavigationButton
                        isActive={activeStep === CustomCampaignSteps.SET_TIME_DELAY && index === activeSendIndex}
                        icon={<AccessTime />}
                        label="time delay"
                        onClick={() => {
                            setActiveSendIndex(index);
                            setActiveStep(CustomCampaignSteps.SET_TIME_DELAY);
                        }}
                        value={timeDelayValue}
                    />
                )}
                {shouldShowConditionalSplitButton && (
                    <CustomCampaignNavigationButton
                        isActive={activeStep === CustomCampaignSteps.CONDITIONAL_SPLIT && index === activeSendIndex}
                        icon={<CallSplit />}
                        label="conditional split"
                        onClick={() => {
                            setActiveSendIndex(index);
                            setActiveStep(CustomCampaignSteps.CONDITIONAL_SPLIT);
                        }}
                    />
                )}
                <CustomCampaignNavigationSend
                    activeSendIndex={activeSendIndex}
                    activeStep={activeStep}
                    index={index}
                    send={send}
                    setActiveSendIndex={setActiveSendIndex}
                    setActiveStep={setActiveStep}
                />
            </div>
            {shouldShowRemoveButton && (
                <IconButton handleClick={() => onRemoveClick(index)} Icon={Close} title="remove" />
            )}
        </div>
    );
};

export default CustomCampaignNavigationBlock;
