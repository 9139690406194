import { useEffect } from 'react';
import { NavigateNext } from '@mui/icons-material';
import { ButtonProps, Stack } from '@mui/material';
import Button from 'domains/core/components/Button';
import DropdownButton from 'domains/core/components/DropdownButton';
import useContentQueryManager from 'hooks/useContentQueryParams';
import theme from 'theme';
import {
    ContentTypeForComparison,
    FolderBreadcrumbData,
    ContentModalTypes,
    FolderLineage,
    RootFolder,
} from 'domains/content/types';

const breadcrumbStyles = {
    height: '32px',
    marginRight: 1,
    padding: 1,
};

type Props = {
    data?: FolderLineage[];
    handleModalOpen: (content: FolderBreadcrumbData, modalType: ContentModalTypes) => void;
    setActiveFolder: (activeFolder: FolderBreadcrumbData | typeof RootFolder) => void;
    showDeleteFolderOption: Boolean;
};

const FolderBreadcrumb = ({ data, handleModalOpen, setActiveFolder, showDeleteFolderOption }: Props) => {
    const { changeViewParams } = useContentQueryManager();

    useEffect(() => {
        if (data?.length > 0) {
            data.forEach((folder) => {
                if (folder.level === 1) {
                    setActiveFolder({ ...folder, type: ContentTypeForComparison.FOLDER });
                }
            });
        } else {
            setActiveFolder(RootFolder);
        }
    }, [data, setActiveFolder]);

    if (!data?.length) return;

    const sharedBreadcrumbProps = (isDropdownButton?: boolean): ButtonProps => ({
        size: 'large',
        variant: 'text',
        sx: {
            ...breadcrumbStyles,
            color: isDropdownButton ? theme.palette.action.active : 'inherit',
        },
    });

    const renderBreadcrumbs = () =>
        data.map((folder) => {
            const isLastChild = folder.level === 1;
            if (isLastChild) {
                const renameOption = {
                    label: 'Rename',
                    onClick: () =>
                        handleModalOpen(
                            {
                                ...folder,
                                type: ContentTypeForComparison.FOLDER,
                            },
                            ContentModalTypes.RENAME
                        ),
                };
                const deleteOption = {
                    label: 'Delete',
                    onClick: () =>
                        handleModalOpen(
                            {
                                ...folder,
                                type: ContentTypeForComparison.FOLDER,
                            },
                            ContentModalTypes.DELETE
                        ),
                };
                const options = showDeleteFolderOption ? [renameOption, deleteOption] : [renameOption];

                return (
                    <DropdownButton
                        key={`${folder.name}${folder.id}`}
                        label={folder.name}
                        options={options}
                        {...sharedBreadcrumbProps(true)}
                    />
                );
            }
            return (
                <Button
                    key={`${folder.name}${folder.id}`}
                    onClick={() => changeViewParams({ newFolderId: folder.id })}
                    endIcon={<NavigateNext />}
                    {...sharedBreadcrumbProps()}
                >
                    {folder.name}
                </Button>
            );
        });

    return (
        <Stack direction="row">
            <Button
                key="all-content"
                onClick={() => changeViewParams({ newFolderId: -1 })}
                endIcon={<NavigateNext />}
                {...sharedBreadcrumbProps()}
            >
                All
            </Button>
            {renderBreadcrumbs()}
        </Stack>
    );
};

export default FolderBreadcrumb;
